const initialState = {
   meilisearchData: [],
   epikLiteData: [],
   proxyApiData: [],
   scannerServicesData: [],
   redisServicesData: [],
   postgreServicesData: [],
   loading: false,
   error: null,
};
const dashboardReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'FETCH_DATA_REQUEST':
         return { ...state, loading: true, error: null };
      // case 'MEILISEARCH_SUCCESS':
      //    return { ...state, loading: false, meilisearchData: action.payload };
      // case 'MEILISEARCH_FAILURE':
      //    return { ...state, loading: false, meilisearchData: action.payload };
      // case 'HTTP_SERVICES_SUCCESS':
      //    return {
      //       ...state,
      //       loading: false,
      //       epikLiteData: action.payload.epik_lite,
      //       scannerServicesData: action.payload.scanner,
      //       proxyApiData: action.payload.proxy_api,
      //    };
      // case 'HTTP_SERVICES_FAILURE':
      //    return {
      //       ...state,
      //       loading: false,
      //       epikLiteData: action.payload,
      //       scannerServicesData: action.payload,
      //       proxyApiData: action.payload,
      //    };
      // case 'REDIS_SERVICES_SUCCESS':
      //    return { ...state, loading: false, redisServicesData: action.payload };
      // case 'REDIS_SERVICES_FAILURE':
      //    return { ...state, loading: false, redisServicesData: action.payload };
      case 'FETCH_ALL_SUCCESS':
         return {
            ...state,
            loading: false,
            epikLiteData: action.payload.epik_lite,
            scannerServicesData: action.payload.scanner,
            proxyApiData: action.payload.proxy_api,
            redisServicesData: action.payload.redis,
            meilisearchData: action.payload.meilisearch,
            postgreServicesData: action.payload.postgres,
         };
      case 'FETCH_ALL_FAILURE':
         return {
            ...state,
            loading: false,
            epikLiteData: action.payload,
            scannerServicesData: action.payload,
            proxyApiData: action.payload,
            redisServicesData: action.payload,
            meilisearchData: action.payload,
            postgreServicesData: action.payload,
         };
      default:
         return state;
   }
};
export default dashboardReducer;
