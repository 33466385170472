const BASE_URL = 'https://monitor-dot-epik-anvil.uc.r.appspot.com';
//const BASE_URL = 'http://localhost:8080';
// const MEILISEARCH_URL = `${BASE_URL}/meilisearch`;
// const HTTP_SERVICES_URL = `${BASE_URL}/http-services`;
// const REDIS_SERVICES_URL = `${BASE_URL}/redis`;

// export const fetchMeilisearchData = () => {
//    return (dispatch) => {
//       dispatch({ type: 'FETCH_DATA_REQUEST' });
//       fetch(MEILISEARCH_URL)
//          .then((response) => response.json())
//          .then((data) => {
//             dispatch({ type: 'MEILISEARCH_SUCCESS', payload: data.meilisearch });
//          })
//          .catch((error) => {
//             dispatch({ type: 'MEILISEARCH_FAILURE', payload: error.message });
//          });
//    };
// };

// export const fetchHttpServicesData = () => {
//    return (dispatch) => {
//       dispatch({ type: 'FETCH_DATA_REQUEST' });
//       fetch(HTTP_SERVICES_URL)
//          .then((response) => response.json())
//          .then((data) => {
//             dispatch({
//                type: 'HTTP_SERVICES_SUCCESS',
//                payload: { epik_lite: data.epik_lite, scanner: data.scanner, proxy_api: data.proxy_api },
//             });
//          })
//          .catch((error) => {
//             dispatch({ type: 'HTTP_SERVICES_FAILURE', payload: error.message });
//          });
//    };
// };

// export const fetchRedisServicesData = () => {
//    return (dispatch) => {
//       dispatch({ type: 'FETCH_DATA_REQUEST' });
//       fetch(REDIS_SERVICES_URL)
//          .then((response) => response.json())
//          .then((data) => {
//             dispatch({ type: 'REDIS_SERVICES_SUCCESS', payload: data.redis });
//          })
//          .catch((error) => {
//             dispatch({ type: 'REDIS_SERVICES_FAILURE', payload: error.message });
//          });
//    };
// };

export const fetchAll = () => {
   return (dispatch) => {
      dispatch({ type: 'FETCH_DATA_REQUEST' });
      fetch(BASE_URL)
         .then((response) => response.json())
         .then((data) => {
            dispatch({
               type: 'FETCH_ALL_SUCCESS',
               payload: {
                  epik_lite: data.epik_lite,
                  scanner: data.scanner,
                  proxy_api: data.proxy_api,
                  redis: data.redis,
                  meilisearch: data.meilisearch,
                  postgres: data.postgres,
               },
            });
         })
         .catch((error) => {
            dispatch({ type: 'FETCH_ALL_FAILURE', payload: error.message });
         });
   };
};
