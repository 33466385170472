import React from 'react';
import { connect } from 'react-redux';

const DashboardPage = ({
   meilisearchData,
   epikLiteData,
   proxyApiData,
   scannerServicesData,
   redisServicesData,
   postgreServicesData,
}) => {
   return (
      <>
         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <h2 className="h2 mb-3 mt-1">Services Monitor</h2>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <hr />
            </div>
         </div>
         <div className="row">
            {meilisearchData.map((item, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="card text-white bg-secondary o-hidden h-100">
                     <div className="card-body">
                        {/* <div className="card-body-icon">
                        <i className="fa fa-fw fa-comments"></i>
                     </div> */}
                        <div className="mr-5">Meilisearch</div>
                        <div className="mt-3">
                           <small>{item.host}</small>
                        </div>
                     </div>
                     <div className="card-footer text-white clearfix small z-1" href="#">
                        <span className={`badge bg-${item.status === 'running' ? 'success' : 'danger'}`}>
                           {item.status}
                        </span>
                        {/* <span className="float-right">
                        <i className="fa fa-angle-right"></i>
                     </span> */}
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <hr />
            </div>
         </div>

         <div className="row">
            {epikLiteData.map((item, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="card text-white bg-secondary o-hidden h-100">
                     <div className="card-body">
                        {/* <div className="card-body-icon">
                        <i className="fa fa-fw fa-comments"></i>
                     </div> */}
                        <div className="mr-5">Epik Lite Api</div>
                        <div className="mt-3">
                           <small>{item.host}</small>
                        </div>
                     </div>
                     <div className="card-footer text-white clearfix small z-1" href="#">
                        <span className={`badge bg-${item.status === 'running' ? 'success' : 'danger'}`}>
                           {item.status}
                        </span>
                        {/* <span className="float-right">
                        <i className="fa fa-angle-right"></i>
                     </span> */}
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <hr />
            </div>
         </div>

         <div className="row">
            {postgreServicesData.map((item, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="card text-white bg-secondary o-hidden h-100">
                     <div className="card-body">
                        {/* <div className="card-body-icon">
                        <i className="fa fa-fw fa-comments"></i>
                     </div> */}
                        <div className="mr-5">Epik Lite PG Database</div>
                        <div className="mt-3">
                           <small>{item.host}</small>
                        </div>
                     </div>
                     <div className="card-footer text-white clearfix small z-1" href="#">
                        <span className={`badge bg-${item.status === 'running' ? 'success' : 'danger'}`}>
                           {item.status}
                        </span>
                        {/* <span className="float-right">
                        <i className="fa fa-angle-right"></i>
                     </span> */}
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <hr />
            </div>
         </div>

         <div className="row">
            {proxyApiData.map((item, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="card text-white bg-secondary o-hidden h-100">
                     <div className="card-body">
                        {/* <div className="card-body-icon">
                        <i className="fa fa-fw fa-comments"></i>
                     </div> */}
                        <div className="mr-5">Lockr Proxy Api</div>
                        <div className="mt-3">
                           <small>{item.host}</small>
                        </div>
                     </div>
                     <div className="card-footer text-white clearfix small z-1" href="#">
                        <span className={`badge bg-${item.status === 'running' ? 'success' : 'danger'}`}>
                           {item.status}
                        </span>
                        {/* <span className="float-right">
                        <i className="fa fa-angle-right"></i>
                     </span> */}
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <hr />
            </div>
         </div>

         <div className="row">
            {scannerServicesData.map((item, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="card text-white bg-secondary o-hidden h-100">
                     <div className="card-body">
                        {/* <div className="card-body-icon">
                        <i className="fa fa-fw fa-comments"></i>
                     </div> */}
                        <div className="mr-5">Scanner: {item.name}</div>
                        <div className="mt-3">
                           <small>Production/Staging</small>
                        </div>
                     </div>
                     <div className="card-footer text-white clearfix small z-1" href="#">
                        <span className={`badge bg-${item.status === 'running' ? 'success' : 'danger'}`}>
                           {item.status}
                        </span>
                        {/* <span className="float-right">
                        <i className="fa fa-angle-right"></i>
                     </span> */}
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <div className="row">
            <div className="col-xl-12 col-sm-12 mb-12">
               <hr />
            </div>
         </div>

         <div className="row">
            {redisServicesData.map((item, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="card text-white bg-secondary o-hidden h-100">
                     <div className="card-body">
                        {/* <div className="card-body-icon">
                        <i className="fa fa-fw fa-comments"></i>
                     </div> */}
                        <div className="mr-5">Redis token storage</div>
                        <div className="mt-3">
                           <small>{item.host}</small>
                        </div>
                     </div>
                     <div className="card-footer text-white clearfix small z-1" href="#">
                        <span className={`badge bg-${item.status === 'running' ? 'success' : 'danger'}`}>
                           {item.status}
                        </span>
                        {/* <span className="float-right">
                        <i className="fa fa-angle-right"></i>
                     </span> */}
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </>
   );
};

const mapStateToProps = (state) => ({
   meilisearchData: state.dashboard.meilisearchData,
   epikLiteData: state.dashboard.epikLiteData,
   proxyApiData: state.dashboard.proxyApiData,
   scannerServicesData: state.dashboard.scannerServicesData,
   redisServicesData: state.dashboard.redisServicesData,
   postgreServicesData: state.dashboard.postgreServicesData,
});

export default connect(mapStateToProps, null)(DashboardPage);
