import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from './../common/header';
import Sidebar from './../common/sidebar';
import { Preloader, Bars } from 'react-preloader-icon';
import { fetchAll } from '../actions/dashboardActions';

const AdminLayout = ({ childComponent: ChildComponent, loading, fetchAll }) => {
   useEffect(() => {
      fetchAll();
      const interval = setInterval(() => {
         fetchAll();
      }, 60000);
      return () => {
         clearInterval(interval);
      };
   }, []);

   if (loading) {
      return (
         <div className="loading-page">
            <div className="center">
               <Preloader use={Bars} size={60} strokeWidth={10} strokeColor="#f7b085" duration={600} />
            </div>
         </div>
      );
   }

   return (
      <div className="d-flex" id="wrapper">
         {/* <!-- Sidebar--> */}
         <Sidebar />
         {/* <!-- Page content wrapper--> */}
         <div className="main" id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <Header />
            {/* <!-- Page content--> */}
            <div className="container-fluid content-container">{ChildComponent && <ChildComponent />}</div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({
   loading: state.dashboard.loading,
});
const mapDispatchToProps = {
   fetchAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
